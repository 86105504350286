import styled from 'styled-components';

export default styled.section`
  display: flex;
  flex-wrap: wrap;

  &.alone {
    & > article {
      width: 100%;
    }
  }
`;
