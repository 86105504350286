import styled from 'styled-components';
import config from '../config';

export default styled.article`
  width: calc(50% - 1.6rem);
  display: flex;
  flex-direction: column;
  margin-top: 3.2rem;

  &:nth-child(2n + 1) {
    margin-right: 3.2rem;
  }

  & > p {
    margin-top: 1.6rem;
    color: ${config.palette.darkBlueTransparent('0.6')};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;

    &:nth-child(2n + 1) {
      margin-right: 0;
    }
  }
`;
