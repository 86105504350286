import React from 'react';
import classNames from 'classnames';
import Layout from '../atoms/layout';
import ListComponent from '../ListComponent';
import IronhackInternationalItem from '../IronhackInternationalItem';
import { Title1 } from '../atoms/headers';
import Section from './IronhackInternational';
import Props from '../interfaces/IronhackInternational';

function IronhackInternational(props: Props) {
  const { title, listQuestions, ...other } = props;

  return (
    <Layout {...other}>
      {title && <Title1>{title}</Title1>}
      <Section className={classNames({ alone: listQuestions.length === 1 })}>
        <ListComponent
          listItems={listQuestions}
          ChildrenComponent={IronhackInternationalItem}
        />
      </Section>
    </Layout>
  );
}

export default IronhackInternational;
