import { css } from 'styled-components';
import config from '../config';

const LayoutCourses = css`
  background-color: ${config.palette.grayBackground};
  padding-bottom: 4.8rem;
  padding-top: 4.8rem;

  h2 {
    margin-bottom: 3.2rem;
    width: 100%;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    flex-direction: column;

    h2 {
      margin-bottom: 2.4rem;
    }
  }
`;

const LayoutCoursesList = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 4.8rem;

  section:nth-child(2) {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }

  section:nth-child(1),
  section:nth-child(2),
  section:nth-child(3) {
    margin-bottom: 3.2rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    flex-direction: column;

    section:nth-child(2) {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

export { LayoutCourses, LayoutCoursesList };
