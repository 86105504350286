import React, { forwardRef } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { getLinkByPageName, getCourseCampusLink } from '../../lib/links';
import { capitalize } from '../../lib/utils';
import Layout from '../atoms/layout';
import ListComponent from '../ListComponent';
import CourseTrackItem from '../CourseTrackItem';
import ListInfoBox from '../ListInfoBox';
import { Title1 } from '../atoms/headers';
import { LayoutCourses, LayoutCoursesList } from './styles';
import Props from '../interfaces/Courses';
import CoursesIcon from '../../../static/assets/all-courses/icon.svg';

const Courses: React.SFC<any> = (props, ref): JSX.Element => {
  const {
    title = '',
    campusCode = '',
    campusCourses = ['web', 'data', 'uxui', 'cysec'],
  } = props;
  const { t } = useTranslation();
  const getLink = (course: string): string => {
    const urlCampusCode = `campus${capitalize(campusCode)}`;
    return campusCode.length > 0
      ? getCourseCampusLink(course, urlCampusCode)
      : getLinkByPageName(course);
  };

  const coursesMap = {
    web: {
      description: t('course-list:courseList.web.description'),
      icon: CoursesIcon,
      link: getLink('courseWeb'),
      title: t('course-list:courseList.web.title'),
    },
    data: {
      description: t('course-list:courseList.data.description'),
      icon: CoursesIcon,
      link: getLink('courseData'),
      title: t('course-list:courseList.data.title'),
    },
    uxui: {
      description: t('course-list:courseList.uxui.description'),
      icon: CoursesIcon,
      link: getLink('courseUxui'),
      title: t('course-list:courseList.uxui.title'),
    },
    cysec: {
      description: t('course-list:courseList.cysec.description'),
      icon: CoursesIcon,
      link: getLink('courseCysec'),
      title: t('course-list:courseList.cysec.title'),
    },
  };

  const courses = campusCourses.map((course) => ({
    ...coursesMap[course],
    course,
  }));

  return (
    <Layout extend={LayoutCourses} id="courses" ref={ref}>
      {title && <Title1 as="h2">{title}</Title1>}
      <Layout extend={LayoutCoursesList} id="coursesList">
        <ListComponent
          ChildrenComponent={CourseTrackItem}
          listItems={courses}
        />
      </Layout>
      {campusCode && (
        <ListInfoBox>
          {t('course-list:courseList.allCourses.selectCourseInfo', {
            campus: t(
              `menu:menu.campuses.options.campus${_.capitalize(
                campusCode
              )}.title`
            ),
          })}
        </ListInfoBox>
      )}
    </Layout>
  );
};

export default forwardRef<HTMLDivElement, Props>(Courses);
