import styled, { css } from 'styled-components';
import Layout from '../../components/atoms/layout';
import config from '../../components/config';

const LayoutSubtitle = styled(Layout)`
  p {
    color: ${config.palette.darkBlueTransparent('0.6')};
    font-weight: 500;
    margin-bottom: 4.8rem;
    margin-top: 1.6rem;
  }
`;

const LayoutIronhackInternational = css`
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;
`;

export { LayoutSubtitle, LayoutIronhackInternational };
