import React from 'react';
import { emitMoreInfoEvent } from './ga_events';
import Section, { Buttom } from './CourseTrackItem';
import { Text1, Text2 } from '../atoms/body';
import Props from '../interfaces/CourseTrankItem';
import { withTranslation } from 'react-i18next';

const CourseTrackItem: React.SFC<any> = (props: Props) => {
  const { title, description, icon: Icon, link, course, t } = props;
  return (
    <Section>
      <Icon alt="Course Icon" />
      <Text1 as="h3">{title}</Text1>
      <Text2>{description.slice(0, 200)}...</Text2>
      <Buttom onClick={(): void => emitMoreInfoEvent(course)} href={link}>
        {t('course-list:courseList.cta')}
      </Buttom>
    </Section>
  );
};

export default withTranslation()(CourseTrackItem);
