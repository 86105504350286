import styled from 'styled-components';
import config from '../config';
import { Link } from 'gatsby';

export default styled.section`
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  align-items: baseline;
  transition: 0.3s;
  width: 30%;

  & > h3 {
    margin-top: 1.6rem;
    margin-bottom: 0.8rem;
  }

  & > b {
    color: ${config.palette.darkBlueTransparent('0.6')};
  }

  &:hover {
    background-color: ${config.palette.white};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }

  &:hover > a {
    background-color: ${config.palette.lightBlue};
    color: ${config.palette.white};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin-bottom: 2.4rem;
    width: 100%;

    &:last-child {
      margin: 0;
    }
  }
`;

export const Buttom = styled.a`
  margin-top: 1.6rem;
  width: 100%;
  text-align: center;
  border-radius: 0.4rem;
  border: 2px solid ${config.palette.lightBlue};
  padding: 1.2rem 0;
  color: ${config.palette.lightBlue};
  font-size: 1.6rem;
  line-height: 125%;
  text-decoration: none;
  transition: 0.3s;

  @media (max-width: ${config.resolutions.mobileL}) {
    background-color: ${config.palette.lightBlue};
    color: ${config.palette.white};
  }
`;
