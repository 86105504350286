import React from 'react';
import { Text3, Text2 } from '../atoms/body';
import Article from './IronhackInternationalItem';
import Props from '../interfaces/IronhackInternationalItem';

function IronhackInternationalItem(props: Props) {
  const { question, answer } = props;

  return (
    <Article>
      <Text2 as="h2">{question}</Text2>
      {Array.isArray(answer) ? (
        answer.map(text => (
          <Text3 key={text} as="p">
            {text}
          </Text3>
        ))
      ) : (
        <Text3 as="p">{answer}</Text3>
      )}
    </Article>
  );
}

export default IronhackInternationalItem;
