import React from 'react';
import withI18next from '../../i18n/TemplateI18n';
import { graphql } from 'gatsby';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import Courses from '../../components/Courses';
import IronhackInternational from '../../components/IronhackInternational';
import Footer from '../../components/Footer';
import { Jumbo2 } from '../../components/atoms/headers';
import { Text2 } from '../../components/atoms/body';
import { LayoutSubtitle, LayoutIronhackInternational } from './styles';
import AllCoursesProps from '../../interface/template';
import BlogArticlesSample from '../../components/BlogArticlesSample';
import useUserLocation from '../../hooks/useUserLocation';
import Banner from '../../components/Banner';

const AllCourses: React.SFC<any> = (props: AllCoursesProps) => {
  const { t, pageContext } = props;
  const { pageName } = pageContext;
  const { userLocation } = useUserLocation();

  return (
    <>
      {userLocation?.country === 'NL' && <Banner type="stap" />}
      <Header showBackground pageName={pageName}>
        <PageTitle
          title={t('all-courses:allCourses.mainHeadline.title')}
          subtitle={t('all-courses:allCourses.mainHeadline.subtitle')}
        />
      </Header>
      <LayoutSubtitle>
        <Jumbo2>{t('all-courses:allCourses.ourCourses.title')}</Jumbo2>
        <Text2 as="p">
          {t('all-courses:allCourses.ourCourses.description')}
        </Text2>
      </LayoutSubtitle>
      <Courses />
      <IronhackInternational
        extend={LayoutIronhackInternational}
        title=""
        listQuestions={[
          {
            answer: t('all-courses:allCourses.ourVision.description'),
            question: t('all-courses:allCourses.ourVision.title'),
          },
          {
            answer: t('all-courses:allCourses.learnByDoing.description'),
            question: t('all-courses:allCourses.learnByDoing.title'),
          },
        ]}
      />
      <BlogArticlesSample category="ALLCOURSES" />
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query ($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(menu)|(course-campus)|(course-list)|(all-courses)|(seo)|(breadcrumbs)|(banner)|(course-web-dev-rmt)|(blog)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next()(AllCourses);
