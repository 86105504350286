import { updateDataLayer } from '../../lib/datalayer';

const courseMap: Record<string, string> = {
  web: 'wd',
  data: 'da',
  uxui: 'ux',
  cysec: 'cy'
};

function emitMoreInfoEvent(courseCode: string): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'interest',
    eventAction: 'click button',
    eventLabel: `${courseMap[courseCode]}::view more information`
  });
}
export { emitMoreInfoEvent };
